import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

type AboutPageContent = {
  paragraphs_s1: string[];
  lang: typeof languages;
  title_s1: string;
  title_s2: string;
  paragraphs_s2: string[];
  links_title: string;
  links_list: {
    title: string;
    desc: string;
    url: string;
  }[];
  partners_title: string;
};

async function fetchAbout(lang: SupportedLanguage) {
  let record: AboutPageContent | null = null;
  try {
    record = await pb
      .collection('about_page')
      .getFirstListItem<AboutPageContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetAbout(): UseQueryResult<AboutPageContent> {
  const lang = useAtomValue(selectedLanguageState);

  return useQuery([`about_content`, lang], () => fetchAbout(lang.code));
}

export default useGetAbout;
