import { QueryClient, QueryClientProvider } from 'react-query';
import DescSection from './components/DescSection';
import Menu from './components/Menu';
import clsx from 'clsx';
import {
  descExpandedState,
  startExpandedState,
} from './state/SectionExpandedState';
import { useAtomValue } from 'jotai';
import PinInfoSection from './components/PinInfoSection';
import { selectedPinState } from './state/SelectedPinState';
import { selectedPinExpandedState } from './state/SelectedPinExpanded';
import MapSection from './components/MapSection';
import useBreakpoint from './hooks/useBreakpoint';
import { MD_BREAKPOINT } from './config/layout';
import { descHeaderHeightState } from './state/descHeaderHeigthState';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 * 60 } },
});

function App() {
  const isDescExpanded = useAtomValue(descExpandedState);
  const descHeaderHeight = useAtomValue(descHeaderHeightState);

  const selectedPin = useAtomValue(selectedPinState);
  const isSelectedPinExpanded = useAtomValue(selectedPinExpandedState);

  const isStart = useAtomValue(startExpandedState);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const transformDesc = (): string => {
    if (isDescExpanded) {
      return isMdScreen ? 'translateY(0)' : 'translateY(-100%)';
    }
    return isMdScreen
      ? `translateY(calc(100vh - ${descHeaderHeight + 75}px))`
      : 'translateY(-90px)';
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-full h-full flex flex-col z-0 justify-items-stretch">
        <main className="w-full md:flex md:flex-row-reverse z-0 overflow-hidden shadow-md shadow-[#2a2f21]">
          <MapSection />

          <div className="md:max-w-[700px] w-fit">
            <Menu />

            <div
              className={clsx(
                'md:w-fit flex-1 transition-all h-full overflow-visible relative z-20 shadow-sm shadow-[#95b15f5e]',
                'w-full z-10 bg-[#FFFEEE]',
                isDescExpanded
                  ? '-translate-y-full md:translate-y-0'
                  : 'md:trans-y-screen-min-180 -translate-y-[90px]',

                isStart && !isMdScreen && '!translate-y-[150vh]'
              )}
              style={{
                transform: transformDesc(),
              }}
            >
              <DescSection />

              {isMdScreen && (
                <div
                  className={clsx(
                    'flex-1 transition-all absolute  bg-[#FFFEEE] z-40',
                    'w-full z-20',
                    isSelectedPinExpanded && '-translate-y-full'
                  )}
                  style={{
                    height: `calc(100% - ${70 + descHeaderHeight}px)`,
                    transform: isSelectedPinExpanded
                      ? `translateY(${descHeaderHeight + 270}px)!important`
                      : '',
                  }}
                >
                  {selectedPin != null && (
                    <PinInfoSection pinInfo={selectedPin} />
                  )}
                </div>
              )}
            </div>

            {!isMdScreen && (
              <div
                className={clsx(
                  'transition-all absolute bottom-0 left-0 h-screen bg-[#FFFEEE] z-10 translate-y-full w-full'
                )}
                style={{
                  transform: isSelectedPinExpanded
                    ? `translateY(max(300px, 40dvh, 40vh))`
                    : '',
                }}
              >
                {selectedPin != null && (
                  <PinInfoSection pinInfo={selectedPin} />
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </QueryClientProvider>
  );
}

export default App;
