import clsx from 'clsx';
import { Spiral as Hamburger } from 'hamburger-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC } from 'react';
import useBreakpoint from '../hooks/useBreakpoint';
import { languageMenuOpenState, MenuOpenState } from '../state/MenuOpenState';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import {
  aboutExpandedState,
  getActiveExpandedState,
  descExpandedState,
  startExpandedState,
  imprintExpandedState,
} from '../state/SectionExpandedState';
import AboutSection from './AboutSection';
import GetActiveSection from './GetActiveSection';
import LanguageMenu from './LanguageMenu';
import { MD_BREAKPOINT } from '../config/layout';
import useGetMenuContent from '../hooks/useGetMenuPage';
import MobileHeaders from './MobileHeaders';
import PrivacyPage from './PrivacySection';
import useOpen from '../hooks/useOpen';
import ToggleWindowButton from './ToggleWindowButton';
import useActionText from '../hooks/useActionText';

interface Props {}

const Menu: FC<Props> = () => {
  const selectedLanguage = useAtomValue(selectedLanguageState);
  const [isMenuOpen, setMenuOpen] = useAtom(MenuOpenState);

  const [isLanguageMenuOpen, setLanguageMenuIsOpen] = useAtom(
    languageMenuOpenState
  );

  const [isDescExpanded, setIsdescExpanded] = useAtom(descExpandedState);
  const [isAboutExpaned, setIsAboutExpanded] = useAtom(aboutExpandedState);
  const [isGetActiveExpaned, setIsGetActiveExpanded] = useAtom(
    getActiveExpandedState
  );

  const setIsImprintExpanded = useSetAtom(imprintExpandedState);

  const isStart = useAtomValue(startExpandedState);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const { data: content } = useGetMenuContent();

  const { open } = useOpen();

  return (
    <menu
      className={clsx(
        'h-[70px] z-40 absolute md:relative top-0 left-0 md:bg-[#FFFFFF] w-full transition-all overflow-visible text-primary',
        isDescExpanded && 'bg-[#FFFFFF]',
        // isGetActiveExpaned && 'bg-[#F0F0F0]',
        !isMdScreen && isStart ? '-translate-y-[100px]' : 'translate-y-0'
      )}
    >
      <ul
        className={clsx(
          'p-4 flex justify-end md:justify-between h-[70px] md:h-[90px] z-50 w-full relative md:bg-[#FFFFFF]',
          selectedLanguage.code === 'bu' ? 'gap-1' : 'gap-4'
        )}
      >
        {isMdScreen && (
          <div
            className={clsx(
              'flex',
              selectedLanguage.code === 'bu' ? 'gap-1' : 'gap-[47px]'
            )}
          >
            <li className="text-menu-item leading-[40px] text-ellipsis flex-shrink">
              <button
                className={clsx(
                  'hover:underline underline-offset-4 decoration-[3px] p-2 whitespace-nowrap text-ellipsis font-normal',
                  isAboutExpaned && 'underline'
                )}
                onClick={() => {
                  open('about');
                  setMenuOpen(false);
                }}
              >
                {content?.about_link ?? 'About'}
              </button>
            </li>
            <li className="text-menu-item leading-[40px] flex-shrink">
              <button
                className={clsx(
                  'hover:underline underline-offset-4 decoration-[3px]  p-2 whitespace-nowrap font-normal',
                  isGetActiveExpaned && 'underline'
                )}
                onClick={() => {
                  open('getActive');
                  setMenuOpen(false);
                }}
              >
                {content?.get_active_link || 'Get Active'}
              </button>
            </li>
          </div>
        )}

        <li className="uppercase mb-[4px] md:col-span-2 flex justify-end overflow-hidden flex-shrink-0">
          <ToggleWindowButton
            isOpen={isLanguageMenuOpen}
            onClick={() => {
              setMenuOpen(false);
            }}
            setIsOpen={setLanguageMenuIsOpen}
            openText={selectedLanguage?.code?.toUpperCase()}
            classNameOpen="!text-[40px] md:text-[50px] hover:no-underline !font-[300]"
            className="uppercase hover:underline underline-offset-4 decoration-[3px] text-lang-mobile md:text-lang font-normal h-fit leading-[31px] md:leading-[55px]"
          />
        </li>
        {!isMdScreen && (
          <li className="flex justify-end -mt-[7px]">
            <Hamburger
              size={25}
              toggled={isMenuOpen}
              toggle={(toggled) => {
                setMenuOpen(toggled);
                setLanguageMenuIsOpen(false);
              }}
            />
          </li>
        )}
      </ul>

      <LanguageMenu />

      {!isMdScreen && (
        <div
          id="mobile-menu"
          className={clsx(
            'pt-[25px] px-[7px] bg-[#FFFFFF] z-30 transition-all w-full relative -mt-[100px] overflow-visible pb-[16px]',
            isMenuOpen ? 'translate-y-0' : '-translate-y-[calc(100%+100px)]'
          )}
        >
          <MobileHeaders />
          <ul className="flex flex-col justify-start items-start p-0 m-0 max-h-min mt-[42px]  overflow-clip gap-[20px]">
            <li className="p-0 m-0 h-[45px]">
              <button
                onClick={() => {
                  open('map');
                  setMenuOpen(false);
                }}
                className="hover:underline text-menu-item-sm p-0 m-0 leading-[27px] h-[45px] align-top"
              >
                {content?.globe_link || 'Globe'}
              </button>
            </li>
            <li className="p-0 m-0 h-[45px]">
              <button
                className="hover:underline text-menu-item-sm p-0 m-0 leading-[27px] h-[45px] align-top"
                onClick={() => {
                  open('about');
                  setMenuOpen(false);
                }}
              >
                {content?.about_link || 'About'}
              </button>
            </li>
            <li className="p-0 m-0 h-[45px]">
              <button
                className="hover:underline text-menu-item-sm p-0 m-0 leading-[45px] h-[45px] align-top"
                onClick={() => {
                  setIsGetActiveExpanded(true);
                  setIsAboutExpanded(false);
                  setIsdescExpanded(false);
                  setLanguageMenuIsOpen(false);
                  setMenuOpen(false);
                  setIsImprintExpanded(false);
                }}
              >
                {content?.get_active_link || 'Get Active'}
              </button>
            </li>
          </ul>
        </div>
      )}

      <AboutSection />
      <PrivacyPage />
      <GetActiveSection />
    </menu>
  );
};

export default Menu;
