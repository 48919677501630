import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { LanguageRow } from '../config/languages';

async function fetchLanguages() {
  let records: LanguageRow[] | null = [];
  try {
    records = await pb
      .collection('languages')
      .getFullList<LanguageRow>(200 /* batch size */, {
        sort: '-created',
      });
  } catch (err) {
    console.log(err);
    throw err;
  }

  return records;
}

const oneDayMs = 1000 * 60 * 60 * 24;

function useGetSupportedLanguages(): UseQueryResult<LanguageRow[]> {
  const { invalidateQueries } = useQueryClient();

  return useQuery('languages', fetchLanguages, {
    /*staleTime: oneDayMs*/
    onSuccess: (data) => {
      invalidateQueries();
    },
  });
}

export default useGetSupportedLanguages;
