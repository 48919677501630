import { FC } from 'react';
import useGetPins from '../hooks/useGetPins';

import Pin from './Pin';

const Pins: FC = () => {
  const { data: mapPins, error } = useGetPins();

  if (error) {
    console.log(error);
  }

  return (
    <>
      {mapPins?.map((pin, idx) => (
        <Pin pin={pin} key={pin.id} idx={idx} />
      ))}
    </>
  );
};

export default Pins;
