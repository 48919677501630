import { useQuery, UseQueryResult } from 'react-query';
import { PinInfo } from '../types/pinInfo';
import pb from '../api/pocketbase';

async function fetchPins() {
  let records: PinInfo[] | null = [];
  try {
    records = await pb
      .collection('pins')
      .getFullList<PinInfo>(200 /* batch size */, {
        sort: '-created',
      });
  } catch (err) {
    console.log(err);
    throw err;
  }

  return records;
}

function useGetPins(): UseQueryResult<PinInfo[]> {
  return useQuery('map_pins', fetchPins);
}

export default useGetPins;
