import { useCallback, useEffect, useState } from 'react';

function useBreakpoint(size: number): boolean {
  const [isOverBreakpoint, setIsOverBreakpoint] = useState(false);

  const handleIfIsOverBreakpoint = useCallback((): void => {
    setIsOverBreakpoint(window.innerWidth > size);
  }, [size]);

  useEffect(() => {
    window.addEventListener('resize', handleIfIsOverBreakpoint);
    handleIfIsOverBreakpoint();

    return () => window.removeEventListener('resize', handleIfIsOverBreakpoint);
  }, [handleIfIsOverBreakpoint]);

  return isOverBreakpoint;
}

export default useBreakpoint;
