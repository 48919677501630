import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';

export type EffectsIcons = {
  drought: string;
  flooding: string;
  wildfires: string;
  loss_of_species: string;
  warming_rising_ocean: string;
  severe_storms: string;
  air_pollution: string;
  heat: string;
  food_water_shortage: string;
};

async function fetchIcons() {
  let record: EffectsIcons | null = null;
  try {
    record = await pb
      .collection('pin_effects_icons')
      .getOne<EffectsIcons>('cclkrt87zbf1jh0');
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetEffectsIcons(): UseQueryResult<EffectsIcons> {
  return useQuery(`get_pin_effects_icons`, fetchIcons);
}

export default useGetEffectsIcons;
