import clsx from 'clsx';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC, useEffect, useRef } from 'react';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import useGetDesc from '../hooks/useGetDesc';
import useGetMenuContent from '../hooks/useGetMenuPage';
import useOpenRandomPin from '../hooks/useOpenRandomPin';
import useWindowDimensions from '../hooks/useWindowDimension';
import { descHeaderHeightState } from '../state/descHeaderHeigthState';
import { languageMenuOpenState } from '../state/MenuOpenState';
import {
  aboutExpandedState,
  getActiveExpandedState,
  descExpandedState,
  imprintExpandedState,
} from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import ToggleWindowButton from './ToggleWindowButton';

interface Props {}

const DescSection: FC<Props> = () => {
  const [isDescExpanded, setIsDescExpanded] = useAtom(descExpandedState);
  const [descHeaderHeight, setDescHeaderHeight] = useAtom(
    descHeaderHeightState
  );

  const isPinExpanded = useAtomValue(selectedPinExpandedState);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const [isAboutExpaned, setIsAboutExpanded] = useAtom(aboutExpandedState);
  const [isImprintExpaned, setIsImprintExpanded] =
    useAtom(imprintExpandedState);
  const [isGetActiveExpaned, setIsGetActiveExpanded] = useAtom(
    getActiveExpandedState
  );

  const setLanguageMenuIsOpen = useSetAtom(languageMenuOpenState);

  const [isSelectedPinExpanded, setIsSelectedPinExpanded] = useAtom(
    selectedPinExpandedState
  );

  useEffect(() => {
    if (isMdScreen) {
      setIsDescExpanded(
        !isAboutExpaned && !isGetActiveExpaned && !isImprintExpaned
      );
    } else {
      setIsDescExpanded(false);
    }
  }, [
    isAboutExpaned,
    isGetActiveExpaned,
    isMdScreen,
    setIsDescExpanded,
    isImprintExpaned,
  ]);

  const { data: content } = useGetDesc();

  const { data: menuContent } = useGetMenuContent();

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [isDescExpanded, scrollBoxRef]);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (!headerRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      setDescHeaderHeight(headerRef?.current?.clientHeight || 70);
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [setDescHeaderHeight, height, width, isPinExpanded, isDescExpanded]);

  const { openRandomPin } = useOpenRandomPin();

  return (
    <section
      className="text-md text-black w-fit h-screen-min-70 bg-[#A2FF30] relative z-40"
      onResize={() => {
        setDescHeaderHeight(headerRef?.current?.clientHeight || 70);
      }}
    >
      <div className="flex relative w-full items-center justify-center min-h-[70px]">
        <button
          disabled={!isMdScreen}
          className={clsx(
            'text-left w-full min-h-[90px] md:min-h-[70px]',
            isMdScreen &&
              (isSelectedPinExpanded || !isDescExpanded) &&
              'hover:underline decoration-[3px] underline-offset-4'
          )}
          onClick={() => {
            setIsDescExpanded(true);
            setIsGetActiveExpanded(false);
            setIsAboutExpanded(false);
            setLanguageMenuIsOpen(false);
            setIsSelectedPinExpanded(false);
            setIsImprintExpanded(false);
          }}
        >
          <h2
            className={clsx(
              'pl-[8px] md:pl-[13px] text-desc-mobile-sm sm:text-desc-mobile md:text-desc w-[calc(100%-80px)] md:w-full leading-[29px] sm:leading-[30px] md:leading-[41px] text-ellipsis  pb-[10px] font-[400]',
              isDescExpanded ? 'md:pt-[25px]' : ''
            )}
            dangerouslySetInnerHTML={{
              __html:
                content?.title_s1 || 'Global Map of Young Climate Activists',
            }}
            ref={headerRef}
          />
        </button>
        {!isMdScreen && (
          <ToggleWindowButton
            isOpen={isDescExpanded}
            setIsOpen={setIsDescExpanded}
            onClick={() => {
              setIsGetActiveExpanded(false);
              setIsAboutExpanded(false);
              setIsImprintExpanded(false);
              setLanguageMenuIsOpen(false);
              setIsImprintExpanded(false);
            }}
          />
        )}
      </div>
      <div
        ref={scrollBoxRef}
        className="text-desc-mobile-sm sm:text-desc-mobile md:text-desc leading-[33px] md:leading-[44px] pt-[25px] md:pt-[33px] h-full overflow-y-scroll overflow-x-hidden  w-full font-normal flex flex-col justify-between"
      >
        <div>
          {content?.paragraphs_s1 ? (
            content?.paragraphs_s1?.map((para) => (
              <p
                key={para}
                className="mb-[33px] indent-10 pl-[8px] md:pl-[13px] pr-[24px]"
              >
                {para}
              </p>
            ))
          ) : (
            <div className="h-[350px] overflow-hidden w-full grid place-items-center">
              <img
                className="h-[130px] w-auto animate-bounce"
                alt="megaphone"
                src={process.env.PUBLIC_URL + "/megaphone.png"}
                height={130}
              />
            </div>
          )}

          <div className="mt-[70px] md:mt-[100px]">
            <p className="mb-[5px] pl-[8px] md:pl-[13px] pr-[24px] w-full">
              <button
                onClick={openRandomPin}
                className="hover:italic underline decoration-[3px] underline-offset-4"
              >
                {content?.explore_the_globe_button || 'Explore the globe!'}
              </button>
            </p>

            <p className="mb-[60px] pl-[8px] md:pl-[13px] pr-[24px] w-full">
              <a
                href={content?.get_active_url}
                className="hover:italic underline decoration-[3px] underline-offset-4"
                target="_blank"
                rel="noreferrer"
              >
                {content?.get_active_button ||
                  'Get active and send us your video'}
              </a>
            </p>
          </div>
        </div>

        <div
          className="md:bg-transparent bg-white min-h-[45px] md:h-[73px] flex w-full relative left-0 m-0 mt-[124px] items-center justify-start gap-[12px] px-[10px]"
          style={{ bottom: isMdScreen ? descHeaderHeight : 90 }}
        >
          <a
            className="text-[18px] underline hover:italic"
            href="https://www.jugendeinewelt.at/impressum/"
          >
            {menuContent?.imprint_link || 'Imprint'}
          </a>
          <button
            onClick={() => {
              setIsGetActiveExpanded(false);
              setIsAboutExpanded(false);
              setLanguageMenuIsOpen(false);
              setIsImprintExpanded(true);
            }}
            className="text-[18px] underline hover:italic"
          >
            {menuContent?.privacy_link || 'Privacy'}
          </button>
          {!isMdScreen && (
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="Green Diversity Logo"
              className="h-[80px] md:h-[110px] absolute right-[15px] md:right-[20px] w-auto bottom-[5px]"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default DescSection;
