import { FC } from 'react';

const MobileHeaders: FC = () => {
  return (
    <>
      <h2 className="m-0 text-menu-title-mobile-sm sm:text-menu-title-mobile mb-[25px] h-full leading-[70px] font-light text-inherit max-w-[400px]">
        Green Diversity
      </h2>
      <h3 className="px-[7px] m-0 text-inherit text-p-sm uppercase font-semibold">
        for climate justice
      </h3>
    </>
  );
};

export default MobileHeaders;
