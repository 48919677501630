import clsx from 'clsx';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC, useEffect, useRef } from 'react';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import useGetGetActive, { LinkItems } from '../hooks/useGetGetActive';
import { descHeaderHeightState } from '../state/descHeaderHeigthState';
import {
  getActiveExpandedState,
  descExpandedState,
} from '../state/SectionExpandedState';
import ToggleWindowButton from './ToggleWindowButton';

interface Props {}

const countriesWithLinks = [
  'austria',
  'spain',
  'italy',
  'finland',
  'germany',
  'intl',
];

const GetActiveSection: FC<Props> = () => {
  const [isOpen, setIsOpen] = useAtom(getActiveExpandedState);
  const setIsDescExpanded = useSetAtom(descExpandedState);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const { data: content } = useGetGetActive();

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [isOpen, scrollBoxRef]);

  const descHeaderHeight = useAtomValue(descHeaderHeightState);

  return (
    <section
      style={{
        height: isMdScreen
          ? `calc(100vh - ${descHeaderHeight + 95}px)`
          : `calc(min(100vh,100dvh) - 90px)`,
      }}
      className={clsx(
        'bg-[#F0F0F0] z-20 transition-all w-full absolute top-0 overflow-hidden text-black', //  text-[#0014FF]
        isOpen
          ? 'translate-y-0 md:translate-y-[90px]'
          : '-translate-y-[calc(100%+100px)]'
      )}
    >
      {isMdScreen && (
        <ToggleWindowButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={() => {
            setIsDescExpanded(isMdScreen);
          }}
        />
      )}

      <div
        ref={scrollBoxRef}
        className="overflow-y-scroll overflow-x-hidden relative h-full pb-[100px]"
      >
        <h2 className="page-title mt-[21px] md:mt-[36px] mb-[100px] pl-[14px] mr-[110px] break-words">
          {content?.title}
        </h2>

        <ul>
          <li>
            <h3 className="pl-[14px] text-menu-item mt-[47px] leading-[41px]">
              {content?.join_project_title}
            </h3>

            <div className="pl-[157px] pr-[45px] mt-[25px] font-degular">
              <p className="text-p-sm leading-[22px]">
                {content?.join_project_desc}
              </p>
              <a
                href="https://www.jugendeinewelt.at/index.php?id=1161"
                target="_blank"
                rel="noreferrer"
                className="underline hover:italic text-p-sm leading-[22px]"
              >
                {content?.send_video_title}
              </a>
            </div>
          </li>

          <li>
            <h3 className="pl-[14px] text-menu-item mt-[47px] leading-[41px]">
              {content?.find_out_more_title}
            </h3>
            <div className="pl-[157px] pr-[45px] mt-[25px] font-degular">
              <p className="text-p-sm leading-[22px]">
                {content?.find_out_more_desc}
              </p>
              <a
                href="http://www.green-diversity.org"
                target="_blank"
                rel="noreferrer"
                className="underline hover:italic text-p-sm leading-[22px]"
              >
                {content?.download_materials_title}
              </a>
            </div>
          </li>

          {content &&
            countriesWithLinks?.map((country) => (
              <li key={country}>
                <h3 className="pl-[14px] text-menu-item mt-[47px] leading-[41px]">
                  {
                    content[
                      `links_${country}_title` as keyof typeof content
                    ] as string
                  }
                </h3>
                <ul className="pl-[157px] pr-[45px] mt-[25px] font-degular">
                  {content[`links_${country}` as keyof LinkItems].map(
                    (item) => (
                      <li
                        key={item.url}
                        className="text-p-sm leading-[22px] mb-[22px]"
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          className="underline hover:italic"
                        >
                          {item.title}
                        </a>
                        {item?.desc && ` - ${item.desc}`}
                      </li>
                    )
                  )}
                </ul>
              </li>
            ))}
        </ul>
        {!isMdScreen && <div className="w-full h-[50px]" />}
      </div>
    </section>
  );
};

export default GetActiveSection;
