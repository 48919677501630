import clsx from 'clsx';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { languageMenuOpenState } from '../state/MenuOpenState';
import {
  fallbackLanguage,
  selectedLanguageState,
} from '../state/SelectedLanguageState';
import useGetSupportedLanguages from '../hooks/useGetSupportedLanguages';
import useActionText from '../hooks/useActionText';

interface Props {}

const LanguageMenu: FC<Props> = () => {
  const { data: langs } = useGetSupportedLanguages();

  const [selectedLanguage, setSelectedLanguage] = useAtom(
    selectedLanguageState
  );

  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useAtom(
    languageMenuOpenState
  );

  const { selectALanguage } = useActionText();

  return (
    <div
      id="language-menu"
      className={clsx(
        'bg-[#FFFFFF] z-30 transition-all w-full absolute -mt-[100px] shadow-sm max-h-screen overflow-clip',
        isLanguageMenuOpen ? 'translate-y-0' : '-translate-y-[calc(100%+100px)]'
      )}
    >
      <h2 className="mt-[32px] pt-[24px] md:mt-[calc(24px+70px)] pb-[15px] pl-[9px] md:pl-[11px] h-full text-menu-item-desc font-semibold leading-[17px] font-degular uppercase">
        {selectALanguage || fallbackLanguage.select_language_text}
      </h2>
      <ul className="pl-[9px] pb-[18px] md:pl-[11px] flex flex-col justify-start items-start gap-[6px] overflow-y-auto max-h-screen-min-160">
        {langs?.map((lang) => (
          <li key={lang.id} className="md:h-[48px] h-[44px] p-0 m-0">
            <button
              onClick={() => {
                setSelectedLanguage(lang);
                setIsLanguageMenuOpen(false);
              }}
              className={clsx(
                'text-menu-item-sm md:text-menu-item leading-[40px] md:leading-[44px] md:h-[48px] h-[44px] font-normal p-0 m-0 align-top hover:italic font-degular',
                selectedLanguage.code === lang.code &&
                  'underline underline-offset-2'
              )}
            >
              {lang.endonym}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageMenu;
