import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

type PinPageContent = {
  lang: typeof languages;
  effects_title: string;
  effects_translation_map: Record<string, string>;
  hear_it_title: string;
  more_title: string;
  more_link_text: string;
};

async function fetchPin(lang: SupportedLanguage) {
  let record: PinPageContent | null = null;
  try {
    record = await pb
      .collection('pin_page')
      .getFirstListItem<PinPageContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }


  return record;
}

function useGetPinPage(): UseQueryResult<PinPageContent> {
  const lang = useAtomValue(selectedLanguageState);

  return useQuery([`pin_content`, lang], () => fetchPin(lang.code));
}

export default useGetPinPage;
