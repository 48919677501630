import { atomWithStorage } from 'jotai/utils';
import { LanguageRow } from '../config/languages';

const fallbackLanguageEn: LanguageRow = {
  id: 'initEn',
  code: 'en',
  endonym: 'English',
  name_en: 'English',
  close_text: 'Close',
  more_text: 'More',
  select_language_text: 'Select a language!',
  imprint_text: 'Imprint',
  privacy_text: 'Privacy',
};

const fallbackLanguageEs: LanguageRow = {
  id: 'initEs',
  code: 'es',
  endonym: 'Español',
  name_en: 'Spanish',
  close_text: 'cerrar',
  more_text: 'más',
  select_language_text: '¡Seleccione un idioma!',
  imprint_text: 'Pie de imprenta',
  privacy_text: 'Política de privacidad',
};

const fallbackLanguageIt: LanguageRow = {
  id: 'initIt',
  code: 'it',
  endonym: 'Italian',
  name_en: 'Italian',
  close_text: 'chiudere',
  more_text: 'altro',
  select_language_text: 'Seleziona una lingua!',
  imprint_text: 'Impronta',
  privacy_text: 'Privacy',
};

function getFallbackLanguage(): LanguageRow {
  switch (process.env.REACT_APP_DEFAULT_LANG) {
    case 'es':
      return fallbackLanguageEs;
    case 'it':
      return fallbackLanguageIt;
    default:
      return fallbackLanguageEn;
  }
}

export const fallbackLanguage: LanguageRow = getFallbackLanguage();

export const selectedLanguageState = atomWithStorage<LanguageRow>(
  'selectedLanguage',
  fallbackLanguage
);
