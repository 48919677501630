import { atom } from 'jotai';

export const aboutExpandedState = atom<boolean>(false);

export const getActiveExpandedState = atom<boolean>(false);

export const descExpandedState = atom<boolean>(false);

export const startExpandedState = atom<boolean>(true);

export const imprintExpandedState = atom<boolean>(false);
