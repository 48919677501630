import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

type ImprintPageContent = {
  title: string;
  lang: typeof languages;
  privacy: string;
};

async function fetchImprint(lang: SupportedLanguage) {
  let record: ImprintPageContent | null = null;
  try {
    record = await pb
      .collection('imprint_page')
      .getFirstListItem<ImprintPageContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetImprint(): UseQueryResult<ImprintPageContent> {
  const lang = useAtomValue(selectedLanguageState);

  return useQuery([`get_imprint_content`, lang], () => fetchImprint(lang.code));
}

export default useGetImprint;
