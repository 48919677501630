import clsx from 'clsx';
import { SetStateAction } from 'jotai';
import { FC } from 'react';
import useActionText from '../hooks/useActionText';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: SetStateAction<boolean>) => void;
  // setExpanded: () => (expanded: SetStateAction<boolean>) => void;
  onClick: () => void;
  className?: string;
  classNameOpen?: string;
  openText?: string;
}

const ToggleWindowButton: FC<Props> = ({
  isOpen,
  setIsOpen,
  onClick,
  openText,
  className,
  classNameOpen,
}) => {
  const { more } = useActionText();

  return (
    <button
      onClick={() => {
        setIsOpen((i) => !i);
        // setExpanded();
        onClick();
      }}
      dangerouslySetInnerHTML={{
        __html: isOpen ? '&times' : openText || more?.toUpperCase(),
      }}
      className={clsx(
        isOpen
          ? classNameOpen ||
              'pr-[7px] text-[40px] md:text-[50px] font-[300] hover:no-underline'
          : 'hover:underline',
        className ||
          'absolute z-30 top-[21px] right-[21px] text-button font-semibold font-degular h-fit leading-10'
      )}
    />
  );
};

export default ToggleWindowButton;
