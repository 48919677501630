import { useSetAtom } from 'jotai';
import { MD_BREAKPOINT } from '../config/layout';
import { descExpandedState } from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import { selectedPinState } from '../state/SelectedPinState';
import useBreakpoint from './useBreakpoint';
import useGetPins from './useGetPins';
import useOpenPin from './useOpenPin';

type UseOpenRandomPinReturn = {
  openRandomPin: () => void;
};

function useOpenRandomPin(): UseOpenRandomPinReturn {
  const { data: mapPins, isLoading: mapPinsLoading, error } = useGetPins();

  const setSelectedPin = useSetAtom(selectedPinState);
  const setIsSelectedPinExpanded = useSetAtom(selectedPinExpandedState);
  const setIsDescExpanded = useSetAtom(descExpandedState);
  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const { openPin } = useOpenPin();

  if (error) {
    console.log(error);
  }

  const openRandomPin = () => {
    if (mapPinsLoading || !mapPins) {
      return;
    }

    const randomPin = mapPins[Math.floor(Math.random() * mapPins.length)];
    setSelectedPin(randomPin);
    setIsSelectedPinExpanded(true);
    openPin(randomPin);
    setIsDescExpanded(isMdScreen);
  };

  return { openRandomPin };
}

export default useOpenRandomPin;
