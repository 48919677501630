import clsx from 'clsx';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC, useEffect, useRef } from 'react';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import useGetAbout from '../hooks/useGetAbout';
import { descHeaderHeightState } from '../state/descHeaderHeigthState';
import {
  aboutExpandedState,
  descExpandedState,
} from '../state/SectionExpandedState';
import ToggleWindowButton from './ToggleWindowButton';

type PartnerContact = {
  logoPath: string;
  title: string;
  title2?: string;
  address: string;
  website_title: string;
  website_url: string;
  email: string;
};

interface Props {}

const partnersList: PartnerContact[] = [
  {
    logoPath: '/partners/jew-logo-hoch.jpg',
    title: 'Jugend Eine Welt',
    title2: 'Don Bosco Entwicklungszusammenarbeit',
    address: 'Münichreiterstraße 31, 1130 Wien, Austria',
    website_title: 'jugendeinewelt.at',
    website_url: 'https://www.jugendeinewelt.at/',
    email: 'info@jugendeinewelt.at',
  },
  {
    logoPath: '/partners/neo-sapiens-logo.png',
    title: 'Neo Sapiens',
    address: 'Hermanos Moroy, 126001 Logroño, La Rioja, Spain',
    website_title: 'neo-sapiens.com',
    website_url: 'https://neo-sapiens.com/',
    email: 'info@neo-sapiens.com',
  },
  {
    logoPath: '/partners/lvia-logo.png',
    title: 'LVIA – Lay Volunteers International Association',
    address: 'Via Mons. Peano, 8 b12100 Cuneo, Italy',
    website_title: 'lvia.it',
    website_url: 'https://lvia.it/',
    email: 'lvia@lvia.it',
  },
  {
    logoPath: '/partners/sci-logo.png',
    title: 'SCI – Service Civil International',
    address: 'Blücherstraße 14D-53115 Bonn, Germany',
    website_title: 'sci-d.de',
    website_url: 'https://sci-d.de/',
    email: 'greendiversity@sci-d.de',
  },
  {
    logoPath: '/partners/kvt-logo.png',
    title: 'KVT - Kansainvälinen vapaaehtoistyö ry',
    address: 'Rauhanasema Veturitori 3, 00520 Helsinki, Finland',
    website_title: 'kvtfinland.org',
    website_url: 'https://kvtfinland.org/',
    email: 'kvt@kvtfinland.org',
  },
];

const AboutSection: FC<Props> = () => {
  const [isOpen, setIsOpen] = useAtom(aboutExpandedState);

  const setIsdescExpanded = useSetAtom(descExpandedState);

  const { data: content } = useGetAbout();

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [isOpen, scrollBoxRef]);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);
  const descHeaderHeight = useAtomValue(descHeaderHeightState);

  return (
    <section
      style={{
        height: isMdScreen
          ? `calc(100vh - ${descHeaderHeight + 95}px)`
          : `calc(min(100vh,100dvh) - 90px)`,
      }}
      className={clsx(
        'bg-[#FFF] z-20 transition-all w-full absolute top-0 overflow-hidden text-black',
        isOpen
          ? 'translate-y-0 md:translate-y-[90px]'
          : '-translate-y-[calc(100%+100px)]'
      )}
    >
      {isMdScreen && (
        <ToggleWindowButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={() => {
            setIsdescExpanded(isMdScreen);
          }}
        />
      )}

      <div
        ref={scrollBoxRef}
        className="overflow-y-scroll overflow-x-hidden h-full md:pb-[0px]"
      >
        <h2 className="page-title mt-[21px] md:mt-[36px] pl-[14px] mr-[110px] break-words">
          {content?.title_s1}
        </h2>
        <img
          src={process.env.PUBLIC_URL + '/logo.png'}
          alt="Green Diversity Logo"
          className="h-[150px] md:h-[200px] w-auto relative -left-4 -bottom-2 md:-bottom-4 mt-[55px]"
        />
        <div className="pl-[14px] mt-[0px] md:pl-[15px] pr-[26px] about-big">
          {content?.paragraphs_s1?.map((para) => (
            <p key={para}>{para}</p>
          ))}
        </div>
        <h3 className="about-h3 mt-[66px] pl-[14px] pr-[18px]">
          {content?.title_s2}
        </h3>
        <div className="pl-[15px] pr-[52px] md:pl-[158px] md:pr-[45px] mt-[23px] about-p-sm font-degular">
          {content?.paragraphs_s2?.map((para) => (
            <p className="mb-[35px]" key={para}>
              {para}
            </p>
          ))}
        </div>
        <h3 className="text-pin-desc-mobile md:text-menu-item leading-[32px] md:leading-[50px] mt-[45px] px-[14px]">
          {content?.links_title}
        </h3>
        <ul className="pl-[14px] pr-[52px] md:pl-[164px] md:pr-[44px]  mt-[23px] md:text-link md:leading-[21px] text-button leading-[19px] font-degular">
          {content?.links_list?.map((link) => (
            <li key={link.url} className="mb-[20px] md:mb-[22px]">
              <a
                href={link.url}
                className="underline hover:italic"
                target="_blank"
                rel="noreferrer"
              >
                {link.title}
              </a>
              {link?.desc !== '' && <p className="">{link.desc}</p>}
            </li>
          ))}
        </ul>
        <h3 className="text-pin-desc-mobile md:text-menu-item leading-[38px] md:leading-[73px] mt-[45px] mb-[12px] pl-[14px]">
          {content?.partners_title}
        </h3>
        <ul className="pl-[16px] pr-[48px] text-button font-degular mt-[23px]">
          {partnersList?.map((partner) => (
            <li key={partner.website_url} className="mb-[40px]">
              <img
                className="h-[100px]"
                src={process.env.PUBLIC_URL + partner.logoPath}
                alt={`${partner.title} logo`}
              ></img>
              <p className="">{partner.title}</p>
              {partner?.title2 && <p className="">{partner.title2}</p>}
              <p className="">{partner.address}</p>
              <a
                href={partner.website_url}
                target="_blank"
                rel="noreferrer"
                className="hover:italic underline"
              >
                {partner.website_title}
              </a>
              <p className="">{partner.email}</p>
            </li>
          ))}
        </ul>

        <h3 className="text-pin-desc-mobile md:text-menu-item leading-[38px] md:leading-[73px] mt-[101px] mb-[7px] pl-[14px]">
          Design & Development
        </h3>
        <div className="pl-[16px] pr-[48px] text-button font-degular mb-[36px]">
          <a className="underline hover:italic" href="http://manulinder.com">
            Manu Linder (Design)
          </a>{' '}
          &{' '}
          <a
            className="underline hover:italic"
            href="https://linkedin.com/in/michael-sattler"
          >
            Michael Sattler (Development)
          </a>
        </div>

        {!isMdScreen && (
          <div className="bg-white h-[55px] md:h-[73px] flex w-full relative mt-[70px] bottom-0">
            <img
              src={process.env.PUBLIC_URL + '/eu-funded.jpg'}
              alt="Funded By EU"
              className="p-2 h-[52px] md:h-[70px] w-auto"
            />
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="Funded By EU"
              className="h-[80px] md:h-[110px] absolute right-[15px] md:right-[20px] bottom-[5px] w-auto"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutSection;
