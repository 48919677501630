import { useSetAtom } from 'jotai';
import { MD_BREAKPOINT } from '../config/layout';
import { languageMenuOpenState } from '../state/MenuOpenState';
import {
  descExpandedState,
  aboutExpandedState,
  imprintExpandedState,
  getActiveExpandedState,
} from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import { selectedPinState } from '../state/SelectedPinState';
import { PinInfo } from '../types/pinInfo';
import useBreakpoint from './useBreakpoint';

type UseOpenPinReturn = {
  openPin: (pin: PinInfo) => void;
};

function useOpenPin(): UseOpenPinReturn {
  const setIsDescExpanded = useSetAtom(descExpandedState);
  const setIsAboutExpanded = useSetAtom(aboutExpandedState);
  const setIsImprintExpanded = useSetAtom(imprintExpandedState);
  const setIsGetActiveExpanded = useSetAtom(getActiveExpandedState);
  const setLanguageMenuIsOpen = useSetAtom(languageMenuOpenState);
  const setIsSelectedPinExpanded = useSetAtom(selectedPinExpandedState);
  const setSelectedPin = useSetAtom(selectedPinState);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const openPin = (pin: PinInfo) => {
    isMdScreen && setIsDescExpanded(true);

    setIsAboutExpanded(false);
    setIsGetActiveExpanded(false);
    setIsSelectedPinExpanded(true);
    setLanguageMenuIsOpen(false);
    setIsImprintExpanded(false);

    setSelectedPin(pin);
  };

  return {
    openPin,
  };
}

export default useOpenPin;
