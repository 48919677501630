import { FC, useMemo, useRef } from 'react';

import EarthDayMap from '../assets/textures_compressed/8k_earth_daymap.png';
import EarthNormalMap from '../assets/textures_compressed/8k_earth_normal_map.jpg';
import EarthSpecularMap from '../assets/textures_compressed/8k_earth_specular_map.jpg';
import EarthCloudsMap from '../assets/textures_compressed/8k_earth_clouds.jpg';

import { useFrame, useLoader } from '@react-three/fiber';
import { Camera, Group, TextureLoader, Vector3 } from 'three';
import {
  OrbitControls,
  OrbitControlsProps,
  PerspectiveCamera,
} from '@react-three/drei';

import * as THREE from 'three';
import Pins from './Pins';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import { useAtomValue } from 'jotai';
import { selectedPinState } from '../state/SelectedPinState';
import { degToRad } from 'three/src/math/MathUtils';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import useWindowDimensions from '../hooks/useWindowDimension';

export const earthCenter = new Vector3(-0.4, 0, 0);

function normalizeAngle(angle: number) {
  // Calculate the angle modulo 2*PI
  angle = angle % (2 * Math.PI);

  // If the angle is negative, add 2*PI to make it positive
  if (angle < 0) {
    angle += 2 * Math.PI;
  }

  return angle;
}

let reset = false;
let firstFrameAfterOpen = true;
let dir = 1;
let zoomDone = false;
let lastPinID: string;

const Earth: FC = () => {
  const [colorMap, normalMap, specularMap, cloudsMap] = useLoader(
    TextureLoader,
    // you can comment out the elements below to test things
    [EarthDayMap, EarthNormalMap, EarthSpecularMap, EarthCloudsMap]
  );

  const isSelectedPinExpanded = useAtomValue(selectedPinExpandedState);

  const earthRef = useRef<Group>(null);

  const selectedPin = useAtomValue(selectedPinState);

  const { width } = useWindowDimensions();

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);
  const isSmScreen = useBreakpoint(600);

  const initZoom = useMemo(
    () => (-(isMdScreen ? 800 : 350) / width) * 18,
    [isMdScreen, width]
  );

  const zoomInDistance = useMemo(() => (isMdScreen ? 10 : 8), [isMdScreen]);

  useFrame(({ camera, controls }) => {
    const orbitControls = controls as OrbitControlsProps;

    if (earthRef.current) {
      if (!isSelectedPinExpanded) {
        if (reset === true) {
          if (
            orbitControls.maxDistance &&
            orbitControls.maxDistance <= zoomInDistance
          ) {
            orbitControls.maxDistance = 25;
          }
          if (!isMdScreen) camera.position.setZ(initZoom);
          reset = false;
          firstFrameAfterOpen = true;
          zoomDone = false;
        }

        earthRef.current.rotation.y += 0.0012;
      } else {
        if (selectedPin?.id && selectedPin.id !== lastPinID) {
          lastPinID = selectedPin.id;
          zoomDone = false;
          firstFrameAfterOpen = true;
        }

        if (!zoomDone) {
          if (selectedPin && selectedPin.lon) {
            earthRef.current.rotation.y = 0;

            if (
              orbitControls.maxDistance &&
              orbitControls.maxDistance > zoomInDistance
            ) {
              orbitControls.maxDistance -= Math.max(
                0.045 * orbitControls.maxDistance,
                0.15
              );
            }

            if (
              orbitControls.setAzimuthalAngle &&
              orbitControls.getAzimuthalAngle
            ) {
              const wantedAngle = Number(
                normalizeAngle(degToRad(90 + selectedPin.lon)).toFixed(2)
              );
              const haveAngle = Number(
                normalizeAngle(orbitControls.getAzimuthalAngle()).toFixed(2)
              );

              const differenceSigned =
                Math.floor((haveAngle - wantedAngle) * 100) / 100;

              const difference = Math.abs(differenceSigned);
              if (firstFrameAfterOpen) {
                dir = differenceSigned > 0 ? -1 : 1;
                firstFrameAfterOpen = false;
              }
              if (difference > 0.1) {
                orbitControls.setAzimuthalAngle(
                  orbitControls.getAzimuthalAngle() + 0.1 * dir
                );
              } else {
                zoomDone = true;
              }
            }

            if (orbitControls.setPolarAngle) {
              orbitControls.setPolarAngle(degToRad(90 - selectedPin.lat));
            }
            reset = true;
          }
        }
      }
    }
  });

  const virtualCamera = useRef<Camera>();

  return (
    <>
      <ambientLight intensity={1.0} />
      <group ref={earthRef}>
        <mesh>
          <sphereGeometry args={[1.004, 128, 128]} />
          <meshPhongMaterial
            map={cloudsMap}
            opacity={0.3}
            depthWrite={false}
            transparent={true}
            side={THREE.DoubleSide}
          />
        </mesh>
        <mesh>
          <sphereGeometry args={[1, 128, 128]} />
          <meshPhongMaterial specularMap={specularMap} />
          <meshStandardMaterial
            map={colorMap}
            normalMap={normalMap}
            metalness={0.4}
            roughness={0.8}
          />
        </mesh>

        <Pins />
      </group>
      <PerspectiveCamera
        ref={virtualCamera}
        position={[0, 0, initZoom]}
        fov={60}
        zoom={isSmScreen && !isMdScreen ? 2.7 : 4.2}
        makeDefault
      />
      <OrbitControls
        enableZoom
        enablePan={false}
        enableRotate
        zoomSpeed={0.25}
        maxDistance={25}
        zoom0={initZoom}
        rotateSpeed={0.65}
        makeDefault
        camera={virtualCamera.current}
      />
    </>
  );
};

export default Earth;
