import { Canvas } from '@react-three/fiber';
import { FC, Suspense } from 'react';
import Earth from './Earth';

interface Props {}

const ThreeCanvas: FC<Props> = () => {
  return (
    <>
      <Canvas className="overflow-hidden">
        <Suspense fallback={null}>
          <Earth />
        </Suspense>
      </Canvas>
    </>
  );
};

export default ThreeCanvas;
