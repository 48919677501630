import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

export type LinkItem = {
  url: string;
  title: string;
  desc?: string;
};

export type LinkItems = Pick<
  GetActivePageContent,
  | 'links_austria'
  | 'links_finland'
  | 'links_germany'
  | 'links_intl'
  | 'links_italy'
  | 'links_spain'
>;

type GetActivePageContent = {
  lang: typeof languages;
  title: string;
  // link_title_url_map: Record<string, string>;
  paragraphs: string[];
  links_intl: LinkItem[];
  links_spain: LinkItem[];
  links_italy: LinkItem[];
  links_germany: LinkItem[];
  // links_france: LinkItem[];
  links_finland: LinkItem[];
  links_austria: LinkItem[];
  links_intl_title: LinkItem[];
  links_spain_title: LinkItem[];
  links_italy_title: LinkItem[];
  links_germany_title: LinkItem[];
  // links_france: LinkItem[];
  links_finland_title: LinkItem[];
  links_austria_title: LinkItem[];

  join_project_title: string;
  join_project_desc: string;
  send_video_title: string;
  find_out_more_title: string;
  find_out_more_desc: string;
  download_materials_title: string;
};

async function fetchGetActive(lang: SupportedLanguage) {
  let record: GetActivePageContent | null = null;
  try {
    record = await pb
      .collection('get_active_page')
      .getFirstListItem<GetActivePageContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetGetActive(): UseQueryResult<GetActivePageContent> {
  const lang = useAtomValue(selectedLanguageState);

  return useQuery([`get_active_content`, lang], () =>
    fetchGetActive(lang.code)
  );
}

export default useGetGetActive;
