import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

type MenuContent = {
  lang: typeof languages;
  globe_link: string;
  about_link: string;
  imprint_link: string;
  privacy_link: string;
  get_active_link: string;
};

async function fetchMenuContent(lang: SupportedLanguage) {
  let record: MenuContent | null = null;
  try {
    record = await pb
      .collection('menu')
      .getFirstListItem<MenuContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetMenuContent(): UseQueryResult<MenuContent> {
  const lang = useAtomValue(selectedLanguageState);

  return useQuery([`menu_content`, lang], () => fetchMenuContent(lang.code));
}

export default useGetMenuContent;
