import { useQuery, UseQueryResult } from 'react-query';
import pb from '../api/pocketbase';
import { useAtomValue } from 'jotai';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { languages, SupportedLanguage } from '../config/languages';

type DescPageContent = {
  lang: typeof languages;
  title_s1: string;
  title_s2: string;
  paragraphs_s1: string[];
  paragraphs_s2: string[];
  get_active_button: string;
  get_active_url: string;
  explore_the_globe_button: string;
};

async function fetchDesc(lang: SupportedLanguage) {
  let record: DescPageContent | null = null;
  try {
    record = await pb
      .collection('desc_page')
      .getFirstListItem<DescPageContent>(`lang="${lang}"`);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return record;
}

function useGetDesc(): UseQueryResult<DescPageContent> {
  const lang = useAtomValue(selectedLanguageState);
  return useQuery([`desc_content`, lang], () => {
    return fetchDesc(lang.code);
  });
}

export default useGetDesc;
