import clsx from 'clsx';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC } from 'react';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import useGetImprint from '../hooks/useGetImprintPage';
import { descHeaderHeightState } from '../state/descHeaderHeigthState';
import {
  descExpandedState,
  imprintExpandedState,
} from '../state/SectionExpandedState';
import CookieBanner from './CookieBanner';
import ToggleWindowButton from './ToggleWindowButton';

interface Props {}

// const contact = {
//   logoPath: '/partners/jew-logo-hoch.jpg',
//   title: 'Jugend Eine Welt e.V.',
//   title2: 'Don Bosco Entwicklungszusammenarbeit',
//   address: 'Münichreiterstraße 31, 1130 Wien, Austria',
//   website_title: 'jugendeinewelt.at',
//   website_url: 'https://www.jugendeinewelt.at/',
//   email: 'info@jugendeinewelt.at',
// };

const PrivacyPage: FC<Props> = () => {
  const [isOpen, setIsOpen] = useAtom(imprintExpandedState);

  const setIsDescExpanded = useSetAtom(descExpandedState);

  const { data: content } = useGetImprint();

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);
  const descHeaderHeight = useAtomValue(descHeaderHeightState);

  return (
    <section
      style={{
        height: isMdScreen
          ? `calc(100vh - ${descHeaderHeight + 95}px)`
          : `calc(min(100vh,100dvh) - 90px)`,
      }}
      className={clsx(
        'bg-gray-300 z-20 transition-all w-full absolute top-0 h-screen-min-90  md:h-screen-min-200 overflow-hidden text-black',
        isOpen
          ? 'translate-y-0 md:translate-y-[90px]'
          : '-translate-y-[calc(100%+100px)]'
      )}
    >
      {isMdScreen && (
        <ToggleWindowButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={() => {
            setIsDescExpanded(isMdScreen);
          }}
        />
      )}

      <div className="overflow-y-scroll overflow-x-hidden h-full pb-[100px] flex flex-col justify-between">
        <h2 className="page-title mt-[21px] md:mt-[36px] mb-[100px] pl-[14px] mr-[110px] break-words">
          {content?.privacy}
        </h2>
        <div className="p-4 flex flex-col gap-4 md:text-[24px]">
          <p>
            Privacy is important to us. This webpage optionally embeds YouTube
            videos to enhance the user experience, but we do not collect any
            personal user data. However, please be aware that YouTube may
            collect information about your activity on their platform in
            accordance with their privacy policies.
          </p>

          <p>
            When you visit our webpage, we do not track your activity or collect
            any personally identifiable information, such as your name, email
            address, or location. We do not use cookies or any other tracking
            technology.
          </p>

          <p>
            When you consent to playing a YouTube video on our webpage, YouTube
            may collect data about your activity on their platform, such as the
            videos you watch, your device information, and your IP address. This
            data is subject to YouTube's privacy policies, which you can read{' '}
            <a
              href="https://policies.google.com/privacy"
              className="underline hover:italic"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>

          <p>
            Please note that we are not responsible for the privacy practices of
            YouTube or any other third-party websites that may be linked to on
            our webpage. We encourage you to read the privacy policies of those
            websites before providing any personal information.
          </p>

          <p>
            If you have any questions or concerns about our privacy policy,
            please contact us at{' '}
            <a href="mailto:online@jugendeinewelt.at">
              online@jugendeinewelt.at
            </a>
            .
          </p>
        </div>

        <h2 className="text-[31px] mt-[18px] md:mt-[36px] pl-[14px] mr-[110px] break-words">
          {content?.privacy} Settings
        </h2>

        <CookieBanner />
        {/* 
        <div
          key={contact.website_url}
          className="mb-[40px] mx-[23px] mt-[80px]"
        >
          <img
            className="h-[100px] mb-[10px]"
            src={contact.logoPath}
            alt={`${contact.title} logo`}
          ></img>
          <p className="">{contact.title}</p>
          {contact?.title2 && <p className="">{contact.title2}</p>}
          <p className="">{contact.address}</p>
          <a
            href={contact.website_url}
            target="_blank"
            rel="noreferrer"
            className="hover:italic underline"
          >
            {contact.website_title}
          </a>
          <p className="">{contact.email}</p>
        </div>

        <h2 className="text-[29px] mt-[18px] mb-[10px] pl-[14px] mr-[110px] break-words">
          {content?.privacy}
        </h2> */}
      </div>
    </section>
  );
};

export default PrivacyPage;
