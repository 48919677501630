import { useSetAtom } from 'jotai';
import { languageMenuOpenState } from '../state/MenuOpenState';
import {
  descExpandedState,
  aboutExpandedState,
  imprintExpandedState,
  getActiveExpandedState,
} from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';

type Panel = 'about' | 'desc' | 'getActive' | 'imprint' | 'map' | 'pin';

type UseOpenReturn = {
  open: (panel: Panel) => void;
  close: (panel: Panel) => void;
};

// opens one panel and closes the other
function useOpen(): UseOpenReturn {
  const setIsDescExpanded = useSetAtom(descExpandedState);
  const setIsAboutExpanded = useSetAtom(aboutExpandedState);
  const setIsImprintExpanded = useSetAtom(imprintExpandedState);
  const setIsGetActiveExpanded = useSetAtom(getActiveExpandedState);
  const setLanguageMenuIsOpen = useSetAtom(languageMenuOpenState);
  const setIsSelectedPinExpanded = useSetAtom(selectedPinExpandedState);

  const open = (panel: Panel) => {
    setIsDescExpanded(panel === 'desc');
    setIsGetActiveExpanded(panel === 'getActive');
    setIsAboutExpanded(panel === 'about');
    setLanguageMenuIsOpen(false);
    setIsSelectedPinExpanded(panel === 'pin');
    setIsImprintExpanded(panel === 'imprint');
  };

  const close = (panel: Panel) => {
    switch (panel) {
      case 'desc':
        setIsDescExpanded(false);
        break;
      case 'getActive':
        setIsGetActiveExpanded(false);
        break;
      case 'about':
        setIsAboutExpanded(false);
        break;
      case 'pin':
        setIsSelectedPinExpanded(false);
        break;
      case 'imprint':
        setIsImprintExpanded(false);
        break;
      default:
        break;
    }
  };

  return {
    open: open,
    close: close,
  };
}

export default useOpen;
