import clsx from 'clsx';
import { useAtom, useSetAtom } from 'jotai';
import { FC } from 'react';
import { acceptCookiesState } from '../state/acceptCookiesState';
import {
  descExpandedState,
  imprintExpandedState,
} from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import deleteAllCookies from '../utils/clearCookies';

interface Props {}

const CookieBanner: FC<Props> = () => {
  const [acceptCookies, setAcceptCookies] = useAtom(acceptCookiesState);

  const [isImprintOpen, setIsImprintOpen] = useAtom(imprintExpandedState);

  const setIsDescExpanded = useSetAtom(descExpandedState);

  const setIsSelectedPinExpanded = useSetAtom(selectedPinExpandedState);

  return (
    <div className="h-fit w-full bg-gray-500 grid place-items-center p-[32px] opacity-80 ">
      <p className="mb-[5px] leading-[1.05]">
        To show videos on this page you need to enable Youtube's third party
        cookies. If you click on 'Accept all Cookies', YouTube will be allowed
        to process your data in the USA, which the European Union does not
        consider a country that protects private data in an adequate way. Read
        YouTube's{' '}
        <a
          className="underline hover:italic"
          target="_blank"
          href="https://policies.google.com/privacy?hl=en-GB"
          rel="noreferrer"
        >
          privacy policy
        </a>{' '}
        to learn more.
      </p>
      <p className="mb-[5px] leading-[1.05]">
        If you are under 16 years old, you are not allowed to accept the
        agreement. Your parents or legal guardians have to accept it for you
      </p>
      {!isImprintOpen && (
        <p className="mb-[5px] leading-[1.05]">
          You can revoke usage of cookies any time or read more about our
          privacy policy by going to the site's{' '}
          <button
            onClick={() => {
              setIsDescExpanded(false);
              setIsImprintOpen(true);
              setIsSelectedPinExpanded(false);
            }}
            className="hover:italic underline"
          >
            privacy section
          </button>
          .
        </p>
      )}
      <div className="flex justify-around w-full text-[24px] leading-6 mt-[13px] ">
        <button
          className={clsx('hover:font-semibold', acceptCookies && 'underline')}
          onClick={() => setAcceptCookies(true)}
        >
          Accept{acceptCookies && 'ed'} all Cookies
        </button>
        <button
          className={clsx('hover:font-semibold', !acceptCookies && 'underline')}
          onClick={() => {
            setAcceptCookies(false);
            deleteAllCookies();
          }}
        >
          Decline{!acceptCookies && 'd'} all Cookies
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
