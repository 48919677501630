/* eslint-disable jsx-a11y/heading-has-content */
import { FC, useEffect, useMemo, useRef } from 'react';
import { ImagesMd, PinInfo, PinInfoStrings } from '../types/pinInfo';
import { useSetAtom, useAtomValue, useAtom } from 'jotai';
import { selectedPinState } from '../state/SelectedPinState';
import { selectedLanguageState } from '../state/SelectedLanguageState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import ReactPlayer from 'react-player';
import useGetPinPage from '../hooks/useGetPinPage';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import { acceptCookiesState } from '../state/acceptCookiesState';
import CookieBanner from './CookieBanner';
import ToggleWindowButton from './ToggleWindowButton';
import useGetEffectsIcons, { EffectsIcons } from '../hooks/useGetEffectsIcons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface Props {
  pinInfo: PinInfo;
}

const PinInfoSection: FC<Props> = ({ pinInfo: p }) => {
  const { data: content } = useGetPinPage();
  const setSelectedPin = useSetAtom(selectedPinState);
  const [isSelectedPinExpanded, setIsSelectedPinExpanded] = useAtom(
    selectedPinExpandedState
  );

  const lang = useAtomValue(selectedLanguageState);

  const countryKey = useMemo(
    () => `country_${lang.code}` as keyof PinInfoStrings,
    [lang]
  );
  const paragraphsKey = useMemo(
    () => `paragraphs_${lang.code}` as keyof PinInfo,
    [lang]
  );

  const markdownKey = useMemo(
    () => `markdown_${lang.code}` as keyof PinInfo,
    [lang]
  );

  const titleKey = useMemo(
    () => `title_${lang.code}` as keyof PinInfoStrings,
    [lang]
  );

  const altKey = useMemo(() => `alt_${lang.code}` as keyof ImagesMd, [lang]);

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const acceptCookies = useAtomValue(acceptCookiesState);

  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [p, scrollBoxRef]);

  const slideShowContainerRef = useRef<HTMLUListElement>(null);
  const arrowImgRef = useRef<HTMLImageElement>(null);
  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const { data: iconsUrls } = useGetEffectsIcons();

  return (
    <div
      className="w-full h-full bg-[#FFFEEE] relative overflow-hidden z-40"
      id="pin-info-section"
    >
      <div className="relative h-full w-full top-0 overflow-hidden">
        <div className="absolute top-0 left-0 h-[60px] w-full pl-[7px] pt-[14px] md:p-[14px]">
          <p className="md:text-p-location text-p-location-mobile leading-[23px] md:leading-[30px] font-[400]">
            {p.location_name}, {p?.[countryKey]}
          </p>
          <ToggleWindowButton
            isOpen={isSelectedPinExpanded}
            setIsOpen={setIsSelectedPinExpanded}
            onClick={() => setSelectedPin(null)}
            className="absolute z-30 top-[0px] right-[14px] text-button font-semibold font-degular h-fit leading-[50px] md:leading-[60px]"
          />
        </div>

        <div
          ref={scrollBoxRef}
          className="pb-[120px] my-[60px] h-full w-full overflow-y-scroll overflow-x-hidden scroll-smooth"
        >
          <h2 className="text-pin-title-mobile md:text-pin-title leading-[52px] md:leading-[60px] pr-[34px] md:pr-[125px] pl-[7px] md:pl-[14px] my-[5px]">
            {p?.[titleKey]}
          </h2>

          <div className="mt-[35px]">
            {!p?.[markdownKey] &&
              p?.[paragraphsKey] &&
              (p?.[paragraphsKey] as string[])?.map((para) => (
                <p
                  className="about-p-sm font-degular pl-[9px] md:pl-[156px] pr-[26px] font-normal pb-[20px]"
                  key={para}
                >
                  {para}
                </p>
              ))}

            {p?.[markdownKey] && (
              <div className="about-p-sm font-degular pl-[9px] md:pl-[156px] pr-[26px] ">
                <ReactMarkdown
                  components={{
                    h1: ({ node, ...props }) => (
                      <h3
                        className="text-[32px] font-[500] pb-[20px]"
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <h4
                        className="text-[26px] font-[500] pb-[20px]"
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <h5
                        className="text-[24px] font-[400] pb-[20px]"
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <h6
                        className="text-[20px] font-[400] pb-[20px]"
                        {...props}
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <p
                        className="about-p-sm font-normal pb-[20px]"
                        {...props}
                      />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul
                        className="list-disc pl-[24px] pb-[20px]"
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <ol
                        className="list-decimal pl-[24px] pb-[20px]"
                        {...props}
                      />
                    ),
                    li: ({ node, ...props }) => (
                      <li className="pb-[8px]" {...props} />
                    ),
                  }}
                  children={p?.[markdownKey] as string}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            )}
          </div>

          <section className="my-[20px]">
            <h3 className="mt-[35px] pl-[10px] pr-[13px] md:px-[26px] pb-[5px] md:pb-[15px] text-pin-subtitle-mobile md:text-pin-subtitle leading-[42px] md:leading-[56px] font-normal">
              {`${content?.effects_title}  `}
            </h3>
            <ul className="pl-[43px] pr-[26px] flex flex-col gap-[12px] md:gap-[17px] mt-[35px]">
              {p?.effects?.map((e) => (
                <li
                  className="flex items-start italic relative min-h-[56px] text-pin-effects-li-mobile leading-[43px] md:text-pin-effects-li md:leading-[56px] align-top"
                  key={e}
                >
                  <img
                    src={
                      iconsUrls?.[e.replace(' ', '_') as keyof EffectsIcons] ||
                      process.env.PUBLIC_URL + '/arrow-glyph.svg'
                    }
                    width={isMdScreen ? '40' : '35'}
                    className="inline-block mt-[7px] md:mt-[12px] mr-[5px]"
                    alt="arrow pointing to the right"
                  />{' '}
                  <span className="bg-[#DDD8FF]">
                    {content?.effects_translation_map?.[e]}
                  </span>
                </li>
              ))}
            </ul>
          </section>

          {p.video_link && (
            <section>
              <div className="relative w-full">
                <img
                  className="absolute -left-[20px] bottom-[8px] h-[100px] md:h-[130px] w-auto -z-10]"
                  alt="megaphone"
                  src={process.env.PUBLIC_URL + '/megaphone.png'}
                />
                <h3 className="mt-[124px] pl-[126px] md:pl-[156px] text-pin-subtitle-mobile md:text-pin-subtitle leading-[41px] md:leading-[56px] pb-[6px] font-normal">
                  {content?.hear_it_title}
                </h3>
              </div>

              {acceptCookies ? (
                <div className="wrapper mt-[19px]">
                  <ReactPlayer
                    className="player"
                    url={p?.video_link}
                    controls
                    width="100%"
                    height="100%"
                    fallback={<div>Video not available</div>}
                    config={{
                      youtube: {
                        playerVars: {
                          modestbranding: 0,
                          cc_lang_pref: lang,
                          cc_load_policy: 1,
                          showinfo: 1,
                          rel: 0,
                          fs: isMdScreen ? 0 : 1,
                          iv_load_policy: 3,
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <CookieBanner />
              )}
            </section>
          )}

          {p?.images_md_list?.length > 0 && (
            <div className="h-[250px] md:h-[320px] overflow-x-hidden w-full flex flex-row">
              <button
                onClick={() => {
                  const beforeScroll =
                    slideShowContainerRef.current?.scrollLeft;
                  const scrollWidth =
                    slideShowContainerRef.current?.scrollWidth;
                  const clientWidth =
                    slideShowContainerRef.current?.clientWidth;

                  const beforeScrollEnd =
                    (beforeScroll ?? 0) + (clientWidth ?? 0) + 1;

                  if ((beforeScrollEnd ?? 0) >= (scrollWidth ?? 0)) {
                    slideShowContainerRef.current?.scrollTo({ left: 0 });
                  } else {
                    slideShowContainerRef.current?.scrollBy({ left: 450 });
                  }
                }}
                className="h-[250px] md:h-[320px] px-[20px] flex-shrink-0 w-auto hover:translate-x-2 transition-all"
              >
                <img
                  ref={arrowImgRef}
                  src={process.env.PUBLIC_URL + '/arrow.svg'}
                  alt="right pointing arrow"
                  className="h-full w-auto transition-all"
                />
              </button>
              <ul
                onScroll={() => {
                  const scrollPosition =
                    slideShowContainerRef.current?.scrollLeft || 0;

                  const scrollWidth =
                    slideShowContainerRef.current?.scrollWidth || 0;

                  const clientWidth =
                    slideShowContainerRef.current?.clientWidth;

                  const beforeScrollEnd =
                    (scrollPosition ?? 0) + (clientWidth ?? 0) + 1; // +1 to not run into weird chrome behavior

                  if (beforeScrollEnd >= scrollWidth) {
                    arrowImgRef.current?.classList.add('rotate-y-180');
                  } else {
                    arrowImgRef.current?.classList.remove('rotate-y-180');
                  }
                }}
                ref={slideShowContainerRef}
                className="h-[250px] md:h-[320px] overflow-x-auto w-full flex flex-row scroll-smooth scrollbar-hide"
              >
                {p?.images_md_list?.map((pic, i) => (
                  <li className="h-[250px] md:h-[320px] flex-shrink-0" key={i}>
                    <img
                      alt={pic[altKey]}
                      src={pic.url}
                      className="w-auto h-full"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {p.more_url && (
            <div className="pl-[10px] pr-[26px] mt-[50px]">
              <a
                href={p?.more_url}
                className="underline hover:italic text-pin-desc-mobile font-normal leading–[30px]"
                target="_blank"
                rel="noreferrer"
              >
                {content?.more_title} →
              </a>
            </div>
          )}

          {!isMdScreen && <div id="filler" className="h-[250px]"></div>}
        </div>
      </div>
    </div>
  );
};

export default PinInfoSection;
