import { useAtomValue } from 'jotai';
import {
  fallbackLanguage,
  selectedLanguageState,
} from '../state/SelectedLanguageState';

type ActionTextReturn = {
  close: string;
  more: string;
  selectALanguage: string;
};

function useActionText(): ActionTextReturn {
  const lang = useAtomValue(selectedLanguageState);

  return {
    close: lang.close_text || fallbackLanguage.close_text,
    more: lang.more_text || fallbackLanguage.more_text,
    selectALanguage:
      lang.select_language_text || fallbackLanguage.select_language_text,
  };
}

export default useActionText;
