import { FC, useMemo, useState } from 'react';

import * as THREE from 'three';

import { useFrame } from '@react-three/fiber';
import { useAtom, useSetAtom } from 'jotai';
import { PinInfo } from '../types/pinInfo';
import { selectedPinState } from '../state/SelectedPinState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import {
  aboutExpandedState,
  descExpandedState,
  getActiveExpandedState,
  imprintExpandedState,
} from '../state/SectionExpandedState';
import useBreakpoint from '../hooks/useBreakpoint';
import { MD_BREAKPOINT } from '../config/layout';

interface Props {
  pin: PinInfo;
  idx: number;
}

const pinColors = [
  //
  '#A2FF30',
  '#FFE8E8',
  '#6F2D89',
  '#FFC20B',
  '#ED115F',
];

const markerHeight = 0;

const phiFromLat = (lat: number) => (90 - lat) * (Math.PI / 180);
const thetaFromLon = (lon: number) => (lon + 180) * (Math.PI / 180);

export const latLongToMarkerVector = (
  lat: number,
  lon: number,
  radius: number
) => {
  var phi = phiFromLat(lat),
    theta = thetaFromLon(lon),
    x = -(radius * Math.sin(phi) * Math.cos(theta)),
    z = radius * Math.sin(phi) * Math.sin(theta),
    y = radius * Math.cos(phi);

  return new THREE.Vector3(
    x + markerHeight,
    y + markerHeight,
    z + markerHeight
  );
};

const Pin: FC<Props> = ({ pin, idx }) => {
  // const materials = useLoader(MTLLoader, 'objects/pin.mtl');
  // const pin3DObj = useLoader(OBJLoader, 'objects/pin.obj', (loader) => {
  //   materials.preload();
  //   loader.setMaterials(materials);
  // });
  const [selectedPin, setSelectedPin] = useAtom(selectedPinState);
  const setIsSelectedPinExpanded = useSetAtom(selectedPinExpandedState);
  const setIsDescExpanded = useSetAtom(descExpandedState);
  const setIsAboutExpanded = useSetAtom(aboutExpandedState);
  const setIsGetActiveExpanded = useSetAtom(getActiveExpandedState);
  const isNotMobileScreen = useBreakpoint(550);
  const pointRadius = useMemo(
    () => (isNotMobileScreen ? 0.4 : 0.8),
    [isNotMobileScreen]
  );

  // const p = pin3DObj.clone().rotateX(-Math.PI / 2);

  const position = latLongToMarkerVector(pin.lat, pin.lon, 1.025);

  const [radius, setRadius] = useState(pointRadius);

  const [isHovered, setIsHovered] = useState(false);

  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const setIsImprintExpanded = useSetAtom(imprintExpandedState);

  useFrame(() => {
    if (selectedPin?.id !== pin.id) {
      setRadius((r) =>
        isHovered
          ? Math.min(r + 0.01, pointRadius * 1.5)
          : Math.max(r - 0.01, pointRadius)
      );
    }
  });

  return (
    <mesh
      key={pin.id}
      position={position}
      scale={0.1}
      onUpdate={(self) => {
        self.lookAt(new THREE.Vector3(0, 0, 0));
      }}
      onClick={(e) => {
        isMdScreen && setIsDescExpanded(true);

        setIsAboutExpanded(false);
        setIsGetActiveExpanded(false);
        setIsSelectedPinExpanded(true);

        setIsImprintExpanded(false);

        setSelectedPin(pin);
      }}
      onPointerEnter={(e) => {
        setIsHovered(true);
      }}
      onPointerOut={(e) => {
        setIsHovered(false);
      }}
    >
      <sphereGeometry args={[radius, 16, 16]} />
      <meshStandardMaterial
        color={pinColors[idx % pinColors.length]}
        transparent
        opacity={0.95}
      />
    </mesh>
  );
};

export default Pin;
