import { useProgress } from '@react-three/drei';
import clsx from 'clsx';
import { useAtom, useSetAtom } from 'jotai';
import { FC, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import { useResizeDetector } from 'react-resize-detector';
import { MD_BREAKPOINT } from '../config/layout';
import useBreakpoint from '../hooks/useBreakpoint';
import { mainHeaderHeightState } from '../state/mainHeaderHeightState';
import { languageMenuOpenState } from '../state/MenuOpenState';
import {
  aboutExpandedState,
  descExpandedState,
  getActiveExpandedState,
  imprintExpandedState,
  startExpandedState,
} from '../state/SectionExpandedState';
import { selectedPinExpandedState } from '../state/SelectedPinExpanded';
import ThreeCanvas from './ThreeCanvas';

interface Props {}

const MapSection: FC<Props> = () => {
  const isNotMobileScreen = useBreakpoint(550);
  const isMdScreen = useBreakpoint(MD_BREAKPOINT);

  const [isStart, setStart] = useAtom(startExpandedState);

  const { progress } = useProgress();

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setStart(false);
      }, 2000);
    }
  }, [setStart, progress]);

  const { width, ref, height } = useResizeDetector();

  const setMainHeaderHeight = useSetAtom(mainHeaderHeightState);
  useEffect(() => {
    setMainHeaderHeight(height || 300);
  }, [height, setMainHeaderHeight, isMdScreen, width]);

  const setIsDescExpanded = useSetAtom(descExpandedState);
  const setIsAboutExpanded = useSetAtom(aboutExpandedState);
  const setIsImprintExpanded = useSetAtom(imprintExpandedState);
  const setIsGetActiveExpanded = useSetAtom(getActiveExpandedState);
  const setIsLanguageMenuIsOpen = useSetAtom(languageMenuOpenState);
  const [isSelectedPinExpanded, setIsSelectedPinExpanded] = useAtom(
    selectedPinExpandedState
  );

  return (
    <Div100vh className="w-full flex-0 overflow-hidden z-0 flex flex-col justify-items-stretch relative">
      {isStart && (
        <header className="w-full grid place-items-center h-full absolute animate-pulse z-50 overflow-hidden">
          <button
            onClick={() => {
              if (progress === 100) setStart(false);
            }}
            className="hover:scale-125 transition-all z-50 relative"
          >
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="Green Diversity Logo"
              width={300}
            />
          </button>
        </header>
      )}
      <header
        className={clsx(
          'px-4 w-full z-0 absolute transition-all overflow-visible',
          isStart && 'trans-y-screen-min-75'
        )}
      >
        <h1
          ref={ref}
          className={clsx(
            'font-[degular-display] font-[300]! transition-all overflow-visible',
            isStart
              ? '-translate-y-full md:text-[6vw] text-black lg:pb-[2px] xl:pb-[25px] 2xl:pb-[40px]'
              : 'translate-y-0 text-primary-light'
          )}
          style={{
            fontSize: (width ?? 375) * (isNotMobileScreen ? 0.173 : 0.25),
            lineHeight: isNotMobileScreen ? 1 : 0.75,
            fontWeight: 300,
            fontStyle: 'normal',
            paddingTop: isNotMobileScreen && !isMdScreen ? 15 : 0,
          }}
        >
          Green {!isNotMobileScreen && <br />}Diversity
        </h1>
      </header>
      <header
        className={clsx(
          'p-2 w-full z-30 absolute',
          isStart ? 'bottom-[-3px]' : 'bottom-[140px] md:bottom-[80px]'
        )}
      >
        {
          <h1
            className={clsx(
              'px-1 transition-all uppercase text-map-sub-mobile md:text-map-sub font-[300]',
              isStart
                ? 'translate-x-0 text-black mb-[13px] px-[13px]'
                : ' text-primary text-right leading-[37px]',
              !isStart && !isMdScreen && 'translate-y-[45px]',
              !isStart && isMdScreen && '-translate-y-[20px]'
            )}
          >
            for Climate Justice
          </h1>
        }
      </header>
      <section
        className={clsx(
          'overflow-visible z-10 h-full flex-1 w-[calc(100%+30vw)] translate-x-[-30vw] md:translate-x-0 md:w-full ml-[30vw] md:ml-0 transition-all',
          isStart ? '-translate-y-[calc(100vh+500px)]' : 'translate-y-0',
          !isMdScreen && isSelectedPinExpanded
            ? '-translate-y-[200px]'
            : 'translate-y-0'
        )}
      >
        <ThreeCanvas />
      </section>
      {!isStart && (
        <footer
          className={clsx(
            'absolute left-1 md:bottom-2 bottom-[90px] flex items-end justify-between w-full z-30'
          )}
        >
          {isMdScreen && (
            <>
              <img
                src={process.env.PUBLIC_URL + '/eu-funded-bg-transparent.png'}
                alt="Funded By EU"
                className="p-2 h-[70px] w-auto"
              />

              <button
                onClick={() => {
                  setIsDescExpanded(true);
                  setIsGetActiveExpanded(false);
                  setIsAboutExpanded(false);
                  setIsImprintExpanded(false);
                  setIsLanguageMenuIsOpen(false);
                  setIsImprintExpanded(false);
                  setIsSelectedPinExpanded(false);
                }}
                className="hover:scale-105 transition-all pb-1"
              >
                <img
                  src={process.env.PUBLIC_URL + '/logo-transparent.png'}
                  alt="Green Diversity Logo"
                  width={160}
                />
              </button>
            </>
          )}
        </footer>
      )}
    </Div100vh>
  );
};

export default MapSection;
